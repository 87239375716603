export const ADD='ADD'
export const LOGIN ='ADD'
export const LOGIN_ERROR ='LOGIN_ERROR'
export const CLOTHIG ='GET'
export const ADDCART ='ADD'
export const IMAGE ='ADD'
export const LINK ='ADD'
export const SUBSLIDER ='SUBSLIDER'
export const TESTI ='TESTI'
export const BLOG ='BLOG'
export const WISH ='WISH'
export const VIDEO ='VIDEO'
export const ADDTOCART ='ADDTOCART'
export const HOMESLIDER ='HOMESLIDER'
export const MOUNTDATA ='MOUNTDATA'
export const COLOR ='COLOR'
export const GETADDRESS ='GETADDRESS'
export const GET_ADDRESS_ERROR ='GET_ADDRESS_ERROR'
export const ORDERDETAIL ='ORDERDETAIL'
export const ALLCATEGORY ='ALLCATEGORY'
export const COLLECTION ='COLLECTION'
export const SIMILAR ='SIMILAR'
export const DATA_FETCH_ERROR ='DATA_FETCH_ERROR'